
document.addEventListener('DOMContentLoaded', function(){
  const toggler = document.querySelector("#toggler");
  toggler.addEventListener('click', () => {
    document.querySelector('#nav-menu').classList.toggle("active");
  });
});

let scrollPos;

document.addEventListener('scroll', function(e) {
  var scrollPos = window.scrollY;

  if (scrollPos > 10) {
    document.querySelector('body').classList.add("scrolled");
  } else {
    document.querySelector('body').classList.remove("scrolled");
  }
});
